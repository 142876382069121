import Image from "next/image";

export default function Header({ company }) {
    return (
        <>
            <header className="header-style-one header-sticky">
                <div className="at_topbar d-none d-sm-block bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-5 col-8">
                                <div className="tp-info">
                                    <p className="mb-0">{`${company.nickName} a melhor revenda de veículos`}</p>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-4">
                                <div className="tp-info-wrapper d-flex align-items-center justify-content-end">
                                    <div className="tp-info d-inline-flex align-items-center">
                                        <span className="icon-wrapper me-2">
                                            <i className="flaticon-phone-call flaticonPlace"></i>
                                        </span>
                                        <a href={`tel:+55${company.phone}`}>
                                            <p className="mb-0">
                                                {
                                                    company.phone.length === 10
                                                        ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 6)}-${company.phone.substring(6, 10)}`
                                                        : company.phone.length === 11
                                                            ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 7)}-${company.phone.substring(7, 11)}`
                                                            : company.phone
                                                }
                                            </p>
                                        </a>
                                    </div>
                                    <div className="d-none tp-info d-xl-inline-flex align-items-center">
                                        <span className="icon-wrapper me-2">
                                            <i className="flaticon-email flaticonPlace"></i>
                                        </span>
                                        <a href={`mailto:${company.email}`}><p className="mb-0">{company.email}</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="at_header_nav">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 col-lg-3">
                                <div className="companyLogo">
                                    <a href="/">
                                        <Image
                                            src={company.logo}
                                            alt="logo"
                                            width={180}
                                            height={85}
                                            sizes="100vw"
                                            quality={100}
                                            loading="lazy"
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-6 col-lg-9">
                                <div className="at_header_right d-flex align-items-center justify-content-end">
                                    <nav className="at_nav_menu d-none d-lg-block">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/novos">0 km</a></li>
                                            <li><a href="/seminovos">Seminovos</a></li>
                                            {/*<li><a href="/blindados">Blindados</a></li>*/}
                                            <li><a href="/sobre-nos">Sobre</a></li>
                                            {/*<li><a href="/financiamento">Financiamento</a></li>*/}
                                            <li><a href="/venda-seu-veiculo">Venda seu Veículo</a></li>
                                            {/*<li><a href="/seguro">Seguro</a></li>*/}
                                            <li><a href="/contato">Contato</a></li>
                                        </ul>
                                    </nav>
                                    <button className="mobile-menu-toggle header-toggle-btn ms-4 d-lg-none" aria-label="Abrir Menu">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-menu position-fixed bg-white deep-shadow">
                <button className="close-menu position-absolute" aria-label="Fechar Menu"><i className="fa-solid fa-xmark"></i></button>
                <a href="/" className="logo-wrapper bg-secondary d-block mt-4 p-3 rounded-1 text-center">
                    <Image
                        src={company.logo}
                        alt="logo"
                        width={180}
                        height={85}
                        sizes="100vw"
                        quality={100} />
                </a>
                <nav className="mobile-menu-wrapper mt-20">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/novos">0 km</a></li>
                        <li><a href="/seminovos">Seminovos</a></li>
                        {/*<li><a href="/blindados">Blindados</a></li>*/}
                        <li><a href="/sobre-nos">Sobre</a></li>
                        {/*<li><a href="/financiamento">Financiamento</a></li>*/}
                        <li><a href="/venda-seu-veiculo">Venda seu Veículo</a></li>
                        {/*<li><a href="/seguro">Seguro</a></li>*/}
                        <li><a href="/contato">Contato</a></li>
                    </ul>
                </nav>
                <div className="contact-info mt-60">
                    <h4 className="mb-20">Contato</h4>
                    <a href={`tel:+55${company.phone}`}>
                        <p>
                            {
                                company.phone.length === 10
                                    ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 6)}-${company.phone.substring(6, 10)}`
                                    : company.phone.length === 11
                                        ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 7)}-${company.phone.substring(7, 11)}`
                                        : company.phone
                            }
                        </p>
                    </a>
                    <a href={`mailto:${company.email}`}><p>{company.email}</p></a>
                    <div className="social-contact">
                        {company.facebook ? <a href={company.facebook} target='_blank' rel="noreferrer" aria-label="Acesse o facebook da nossa loja"><i className="fab fa-facebook-f"></i></a> : null}
                        {company.instagram ? <a href={company.instagram} target='_blank' rel="noreferrer" aria-label="Acesse o instagram da nossa loja"><i className="fab fa-instagram"></i></a> : null}
                        {company.youtube ? <a href={company.youtube} target='_blank' rel="noreferrer" aria-label="Acesse o youtube da nossa loja"><i className="fab fa-youtube"></i></a> : null}
                    </div>
                </div>
            </div>
        </>
    );
}